<template>
  <div>
    <v-card
      max-width="500"
      :min-height="$vuetify.breakpoint.mdAndUp ? '625' : '550' "
      :class="$vuetify.breakpoint.mdAndUp ? 'card-radius' : ''"
      class="my-md-8  mx-auto"
      color="#C3FFF5"
      flat
    >
      <v-row class="justify-lg-end justify-space-between mx-6 pt-4" >
         <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="primary--text" large  @click="$router.go(-1)" >mdi-arrow-left-drop-circle-outline</v-icon >
        <lang-swicther />
      </v-row>
      <v-card-text class="pt-16"> 
        <v-img
          :src="require('../../../assets/logo.png')"
          width="90px"
          contain
          class="mx-auto"
        />
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col class="text-center mx-12">
            <h3>{{ $t('password.title') }}</h3>
          </v-col>
        </v-row>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit,valid }">
          <form @submit.prevent="handleSubmit(onSubmit)" v-if="!message">
            <v-layout>
              <v-flex>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="New Password"
                  rules="required|max:20|min:6"
                  vid="newPassword"
                >
                  <v-text-field
                    class="custom-field mx-auto mt-5"
                    :error-messages="errors"
                    v-model="newPassword"
                    type="password"
                    :placeholder="$t('password.newPassword')"
                    required
                    maxlength="20"
                    outlined
                    rounded
                    dense
                  ></v-text-field>
                </ValidationProvider>
              </v-flex>
            </v-layout>
            <v-layout class="mt-2">
              <v-flex>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:newPassword"
                >
                  <v-text-field
                    class="custom-field mx-auto "
                    type="password"
                    :error-messages="errors"
                    v-model="confirmPassword"
                    :placeholder="$t('password.confirmPassword')"
                    required
                    outlined
                    maxlength="20"
                    rounded
                    dense
                  ></v-text-field>
                </ValidationProvider>
              </v-flex>
            </v-layout>
            <v-layout justify-center class="mt-4">
              <v-btn
                depressed
                rounded
                color="primary custom-font"
                class="black--text font-weight-light mt-2"
                :width="$vuetify.breakpoint.xs ? 220 : 260"
                type="submit"
                :disabled="!valid"
                :loading="btn_loading"
                >{{
                  $t('password.btnTitle')
                }}</v-btn
              >
            </v-layout>
            
          </form>
<div class="mt-5" v-else>

             <p class="text-center">  {{message}}
             </p>
             <p class="text-center">
              <router-link :to="{name:'Login'}" class="text-center black--text">Login here</router-link>
             </p>
            </div>
        </ValidationObserver>
        
        <p v-if="errorFromApi" class=" text-center  mt-6">
          <router-link class="red--text  " :to="{name:'ForgetPassword'}"> {{ errorFromApi }} </router-link>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {verifyCustomer} from "../../methods/verifyCustomer"
export default {
  data () {
    return {
      btn_loading: false,
      errorFromApi: null,
      newPassword: '',
      confirmPassword: '',
      message:null,
    }
  },
 components: {
   "lang-swicther": () => import("@/core/components/LangSwitch")
 },
  methods: {
    onSubmit() {
      this.$refs.observer.validate();
      this.btn_loading = true;
      let input = {password:this.newPassword,userId:this.$store.getters.otpDetails.user._id,otp:this.$store.getters.otpDetails.otp}
      verifyCustomer({input:input}).then(({data})=>{
      this.btn_loading = false;
        if(data?.verifyCustomer._id){
        this.message = 'Password successfully changed'
        }
        if(!data){
          this.message = null
          this.errorFromApi = "Your Otp is expired , Send new OTP"
        }
        })
    }
  }
}
</script>

<style scoped>

.custom-field >>> .v-input__slot{
  background-color: white !important;
}
</style>
