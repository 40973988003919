import { apolloClient } from "@/plugins/vue-apollo.js";

const verifyCustomer = (_variables) => {
  let responseFromApi;
  let variables = _variables;
  responseFromApi = apolloClient
    .mutate({
      mutation: require("../api/mutations/forgetPassword/verifyCustomer.graphql"),
      variables: variables,
    })
    .catch(error => {
      return error;
    });
  return responseFromApi;
};
export { verifyCustomer };
