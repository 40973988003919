var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"my-md-8  mx-auto",class:_vm.$vuetify.breakpoint.mdAndUp ? 'card-radius' : '',attrs:{"max-width":"500","min-height":_vm.$vuetify.breakpoint.mdAndUp ? '625' : '550',"color":"#C3FFF5","flat":""}},[_c('v-row',{staticClass:"justify-lg-end justify-space-between mx-6 pt-4"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-icon',{staticClass:"primary--text",attrs:{"large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left-drop-circle-outline")]):_vm._e(),_c('lang-swicther')],1),_c('v-card-text',{staticClass:"pt-16"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require('../../../assets/logo.png'),"width":"90px","contain":""}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center mx-12"},[_c('h3',[_vm._v(_vm._s(_vm.$t('password.title')))])])],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [(!_vm.message)?_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-layout',[_c('v-flex',[_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required|max:20|min:6","vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"custom-field mx-auto mt-5",attrs:{"error-messages":errors,"type":"password","placeholder":_vm.$t('password.newPassword'),"required":"","maxlength":"20","outlined":"","rounded":"","dense":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)})],1)],1),_c('v-layout',{staticClass:"mt-2"},[_c('v-flex',[_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"custom-field mx-auto ",attrs:{"type":"password","error-messages":errors,"placeholder":_vm.$t('password.confirmPassword'),"required":"","outlined":"","maxlength":"20","rounded":"","dense":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1)],1),_c('v-layout',{staticClass:"mt-4",attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"black--text font-weight-light mt-2",attrs:{"depressed":"","rounded":"","color":"primary custom-font","width":_vm.$vuetify.breakpoint.xs ? 220 : 260,"type":"submit","disabled":!valid,"loading":_vm.btn_loading}},[_vm._v(_vm._s(_vm.$t('password.btnTitle')))])],1)],1):_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('p',{staticClass:"text-center"},[_c('router-link',{staticClass:"text-center black--text",attrs:{"to":{name:'Login'}}},[_vm._v("Login here")])],1)])]}}])}),(_vm.errorFromApi)?_c('p',{staticClass:" text-center  mt-6"},[_c('router-link',{staticClass:"red--text  ",attrs:{"to":{name:'ForgetPassword'}}},[_vm._v(" "+_vm._s(_vm.errorFromApi)+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }